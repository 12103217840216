@font-face {
  font-family: 'Cicero';
  src: url(../media/fonts/BNCicero.otf);
}

@font-face {
  font-family: 'Dixon';
  src: url(../media/fonts/BNDixon.otf);
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

header {
  width: 100vw;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
}

header > svg {
  height: 46px;
  margin: 5px 10px;
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

div.map-container {
  margin-top: 100px;
  width: 100vw;
  height: 500px;
  position: relative;
  z-index: 1;
}

div.grid-container {
  width: 100vw;
  display: flex;
  position: relative;
  z-index: 2;
  flex-wrap: wrap;
  justify-content: center;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

div.grid-container > div {
  width: 240px;
  height: 200px;
  margin: 30px;
  border-radius: 20px;
  opacity: 0.8;
  padding: 20px;
  font-family: 'Dixon';
  font-size: 22px;
  line-height: 1.1em;
  position: relative;
}

div.grid-container svg {
  position: absolute;
  padding: 15px;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

footer {
  width: 100vw;
  height: 60px;
  display: flex;
  justify-content: center;
  margin: 100px 0px;
}

footer > svg {
  height: 60px;
}
