div.intro {
  width: 100vw;
  height: 700px;
}

div.intro > svg {
  opacity: 0.2;
}

div.desc {
  width: 70vw;
  font-size: 60px;
  position: absolute;
  top: 60px;
  padding-left: 20vw;
  padding-top: 200px;
  font-family: 'Cicero';
}

div.instruct {
  font-family: 'Dixon';
  font-size: 24px;
  padding: 10px 100px;
  z-index: 2;
}
